import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../common/layouts/';
import Seo from '../common/seo';
import 'tachyons';
import Img from "gatsby-image";
import {graphql} from "gatsby";


const waxing = [
    'Back,$25',
    'Bikini,$18',
    'Bikini w/ thigh,$25',
    'Bikini w/ top,$20',
    'Cheeks,$7',
    'Chest,$7',
    'Chin,$6',
    'Eyebrow,$10',
    'Forehead,$6',
    'Full Arms,$25',
    'Full Face,$35',
    'Full Legs,$45',
    'Half Arm,$20',
    'Half Leg,$30',
    'Neck,$7',
    'Nose,$6',
    'Side Burns,$10',
    'Stomach,$15',
    'Upper lip,$5'

]

const facial = [
    'Massage Facial,$60',
    'Nose Facial,$15',
    'Teen Facial,$40'
]

const threading = [
    'Eyebrow,$9',
    'Cheeks,$5',
    'Chin,$5',
    'Forehead,$6',
    'Full Face,$28',
    'Neck,$7',
    'Sideburns,$8',
    'Upper lip,$5'
]

export default ({props,data}) => (
    <Layout>
        <Seo
            title="Services"
            description="Beauty services provided by Fusion Brow Bar"/>
        <Helmet>
            <meta name="robots" content="noindex, nofollow"/>
        </Helmet>

        <div className="relative ">
            <Img fluid={data.banner.childImageSharp.fluid}
            />
            <h1
                className="fw1 tc f1 display absolute dark-gray dn dib-ns"
                style={{bottom: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>Services</h1>
        </div>
        <div
            className="h-auto bg-near-white mw9 w-100 flex flex-column items-center justify-center pv5 ph2 center">
            <br/>
            <br/>

            <div>
                <div>

                    <div className='row'>
                        <div className='column'>

                            <div className='pa2 display dark-gray f2 b tc mb3 h3 fadeIn'>
                                Threading
                                <div className="w-100 bb b--mid-gray mv3"></div>
                            </div>
                            <table>
                                {
                                    threading.map(e => {

                                        return <tr className='pa2 display gray f4 tc mb3 fadeIn'>
                                            <td>{e.split(',')[0]}</td>
                                            <td>{e.split(',')[1]}</td>
                                        </tr>

                                    })
                                }
                            </table>
                        </div>
                        <div className='column'>

                        </div>
                    </div>

                </div>
                <div>
                    <div className='pa2 display dark-gray f2 b tc mb3 h3 fadeIn'>Facial
                        <div className="w-100 bb b--mid-gray mv3"></div>
                    </div>
                    <table>
                        {
                            facial.map(e => {
                                return <tr className='pa2 display gray f4 tc mb3 fadeIn'>
                                    <td>{e.split(',')[0]}</td>
                                    <td>{e.split(',')[1]}</td>
                                </tr>

                            })
                        }
                    </table>
                </div>
                <div className='column'>
                    <div className='pa2 display dark-gray f2 b tc mb3 h3 fadeIn'>
                        Waxing
                        <div className="w-100 bb b--mid-gray mv3"></div>
                    </div>

                    <table>
                        {
                            waxing.map(e => {
                                return <tr className='pa2 display gray f4 tc mb3 fadeIn'>
                                    <td>{e.split(',')[0]}</td>
                                    <td>{e.split(',')[1]}</td>
                                </tr>
                            })
                        }
                    </table>
                </div>


            </div>


        </div>

    </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: {name: {eq: "about__bio"}}) {
      html
      frontmatter {
        title
      }
    }
    banner: file(relativePath: {eq: "img/services_banner.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 500, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
